@use '@txt/client/styles/txture.scss';

.tooltip-component {
  // apply the display to each element of the generated wrapper structure
  &.display-inline {
    &,
    & > div,
    & > div > div.tooltip-wrapper,
    & > div > div.tooltip-wrapper > div.tooltip-wrapper-inner {
      display: inline;
    }
  }
  &.display-block {
    &,
    & > div,
    & > div > div.tooltip-wrapper,
    & > div > div.tooltip-wrapper > div.tooltip-wrapper-inner {
      display: block;
    }
  }
  &.display-inline-block {
    &,
    & > div,
    & > div > div.tooltip-wrapper,
    & > div > div.tooltip-wrapper > div.tooltip-wrapper-inner {
      display: inline-block;
    }
  }

  > .popper,
  > .popper.popper--dark {
    $tooltip_color: txture.$colorsText;

    background: $tooltip_color;
    color: white;
    border-radius: 2px;
    box-shadow: txture.$shadowsLayer1;
    padding: 12px;
    z-index: var(--portal-zIndex);
    max-width: 400px;

    .popper__arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
    }

    &[data-popper-placement^='top'] .popper__arrow {
      border-width: 5px 5px 0 5px;
      border-color: $tooltip_color transparent transparent transparent;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }

    &[data-popper-placement^='bottom'] .popper__arrow {
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $tooltip_color transparent;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }

    &[data-popper-placement^='right'] .popper__arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent $tooltip_color transparent transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }

    &[data-popper-placement^='left'] .popper__arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $tooltip_color;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  > .popper--light {
    $tooltip_color: white;

    background: $tooltip_color;
    color: inherit;

    &[data-popper-placement^='top'] .popper__arrow {
      border-color: $tooltip_color transparent transparent transparent;
    }

    &[data-popper-placement^='bottom'] .popper__arrow {
      border-color: transparent transparent $tooltip_color transparent;
    }

    &[data-popper-placement^='right'] .popper__arrow {
      border-color: transparent $tooltip_color transparent transparent;
    }

    &[data-popper-placement^='left'] .popper__arrow {
      border-color: transparent transparent transparent $tooltip_color;
    }
  }
}
